import {
  Action,
  Article,
  ArticleHeader,
  ArticleType,
  Dictionary,
  DictionaryEntry,
  Highlights,
  IconType,
  Image,
  JsonLD,
} from "@nettbureau/gatsby-theme-katalog";
import { graphql } from "gatsby";
import { get } from "lodash";
import React from "react";
import { RelatedProducts } from "../components";
import no from "../messages";
import { Details, Detail } from "../components/Details";

interface Props {
  data: {
    product: any;
    author: any;
  };
}

interface RegionMap {
  [key: string]: string;
}

const regionMap: RegionMap = {
  regionNO5: "Vest",
  regionNO4: "Nord",
  regionNO3: "Midt",
  regionNO2: "Sør",
  regionNO1: "Øst",
};

export default ({ data: { product, author } }: Props) => {
  return (
    <>
      <JsonLD
        schema={{
          "@context": "https://schema.org",
          "@type": "Product",
          name: product.productName,
          category: ["Strømavtale", "Strømprodukt"],
        }}
      />
      <ProductHeader product={product} />

      <Article type={ArticleType.BRAND}>
        <div className="brand--h1">
          <p>Vurdering av strømavtalen</p>
          <h1>
            <em>{product.productName}</em> fra {product.company.companyName}
          </h1>
        </div>

        <Highlights
          values={resolveAndFormatHighlights(
            [
              "productType",
              "applicableToCustomers",
              "fixedPrice",
              "addonPrice",
              "monthlyFee",
              "agreementTime",
              "feeMail",
            ],
            product
          )}
        />

        <br />

        {shouldDisplayCoverageWarning(product) && (
          <p style={{ textAlign: "center" }}>
            Obs, avtalen dekker ikke hele Norge
          </p>
        )}

        {shouldDisplayPriceWarning(product) && (
          <p style={{ textAlign: "center" }}>
            Obs, avtalen har ulike priser avhengig av hvilken region du tilhører
          </p>
        )}

        <h2>{"Om strømavtalen"}</h2>

        {no.markdown("TEMPLATE_product", product)}

        <Dictionary
          title={"Ordliste"}
          dictionary={resolveDictionaryEntries([
            "paslag",
            "spotpris",
            "innkjopspris",
            "variabel_pris",
            "fastpris",
            "andre_prisavtaler",
            "bindingstid",
            "nye_kunder",
          ])}
          author={{
            name: "Ingunn Mjønerud",
            thumbnail: get(author, "childImageSharp.fixed"),
            description: (
              <span>
                <b>Ingunn Mjønerud</b> er journalist og innholdsprodusent hos
                Strøm.no. Her gir hun deg en kort innføring i de ulike begrepene
                knyttet til strømavtaler.
              </span>
            ),
            quote:
              "«Forbrukerne tjener på økt åpenhet i strømbransjen – derfor gjør vi det enkelt for kunder å sette seg inn i begreper og avtalevilkår før de bestiller ny strømavtale.»",
          }}
        />
        <Details
          title="Detaljer"
          details={[
            ...resolveAndFormatFields(
              [
                "productType",
                "applicableToCustomers",
                "agreementTime",
                "monthlyFee",
                "addonPrice",
                "addonPriceMinimumFixedFor",
                "maxkwYear",
                "kwPriceExcessUsage",
                "memberships",
                "otherConditions",
              ],
              product
            ),
            ...buildRegionForDetails(product),
          ]}
        />
        <Details
          title="Om strømregningen"
          details={resolveAndFormatFields(
            ["paymentType", "billingFrequency"],
            product
          )}
        />
        <Details
          title="Betaling"
          details={resolveAndFormatFields(
            ["feeAvtalegiroMandatory", "feeEFakturaMandatory", "feeMail"],
            product
          )}
        />
        <Action
          link={product.orderAction.link}
          note={product.orderAction.sponsored ? "Annonselenke" : "\u00a0"}
        />
        <RelatedProducts
          title={`Flere strømavtaler fra ${product.company.companyName}`}
          products={product.company.products}
          relatedTo={product}
        />
      </Article>
    </>
  );
};

const ProductHeader = ({ product }: any) => {
  const logo = get(product, ["company", "cms", "logo"]);
  return (
    <ArticleHeader
      logo={
        logo ? (
          <Image
            alt={product.company.companyName}
            {...logo}
            options={{ height: 50 }}
          />
        ) : (
          <h4>{product.company.companyName}</h4>
        )
      }
    >
      <Action
        link={product.orderAction.link}
        title={product.productName}
        note={product.orderAction.sponsored ? "Annonselenke" : "\u00a0"}
      />
    </ArticleHeader>
  );
};

function shouldDisplayPriceWarning(product: any) {
  if (!product.hasOwnProperty("productArea")) {
    return false;
  }

  if (product.productArea.length !== 5) {
    return false;
  }

  let previousPrice = 0;

  for (const productArea of product.productArea) {
    if (previousPrice !== 0 && previousPrice !== productArea.kwPrice) {
      return true;
    }
    previousPrice = productArea.kwPrice;
  }

  return false;
}

function shouldDisplayCoverageWarning(product: any) {
  if (!product.hasOwnProperty("productArea")) {
    return false;
  }

  if (product.productArea.length === 5) {
    return false;
  }

  return true;
}

function buildRegionForDetails(product: any): Detail[] {
  if (product.hasOwnProperty("productArea")) {
    const regions = product.productArea.map((area: { region: string }) => {
      return area.region in regionMap ? regionMap[area.region] : area.region;
    });
    return [
      {
        key: "region",
        value: regions.reduce((prev: string, current: string) => {
          return `${prev}, ${current}`;
        }),
        label: "Hvilken regioner gjelder avtalen?",
      },
    ];
  }
  return [];
}

function resolveAndFormatHighlights(details: string[], product: any) {
  return details
    .filter(
      (detail) => product.hasOwnProperty(detail) && product[detail] !== null
    )
    .map((key) => {
      const iconKey = no.string(`ICON_${key}`, product, {
        fallback: IconType.GRAPH,
      }) as IconType;
      return {
        key,
        value:
          no.markdown(`HIGHLIGHT_${key}`, product) ||
          no.markdown(`VALUE_${key}`, product),
        description: no.string(`DESCRIPTION_${key}`, product, {
          fallback: key,
        }),
        icon: IconType[iconKey],
      };
    })
    .filter((entry) => !!entry.value)
    .slice(0, 4);
}

function resolveDictionaryEntries(entries: string[]): DictionaryEntry[] {
  return entries.map((entry) => ({
    word: no.string(`DICTIONARY_WORD_${entry}`),
    explanation: no.markdown(`DICTIONARY_EXPLANATION_${entry}`),
  }));
}

function resolveAndFormatFields(details: string[], product: any): Detail[] {
  return details
    .filter(
      (detail) => product.hasOwnProperty(detail) && product[detail] !== null
    )
    .map((key) => {
      return {
        key,
        label: no.string(`LABEL_${key}`, product, { fallback: key }),
        value: no.markdown(`VALUE_${key}`, product),
      };
    })
    .filter((entry) => !!entry.value);
}

export const query = graphql`
  query ($id: String!) {
    author: file(name: { eq: "ingunn" }) {
      childImageSharp {
        fixed(width: 80, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    product: stromProduct(id: { eq: $id }) {
      addonPrice
      productArea {
        kwPrice
        region
      }
      addonPriceMinimumFixedFor
      addonPriceMinimumFixedForUnits
      agreementTime
      agreementTimeUnits
      applicableToCustomers
      billingFrequency
      billingFrequencyUnits
      elCertificatePrice
      feeAvtalegiroMandatory
      feeEFakturaMandatory
      feeMail
      feeMailApplied
      fixedPrice
      maxkwYear
      memberships
      monthlyFee
      otherConditions
      paymentType
      priceFromDate
      productName
      productType
      willNotifyDirectlyChannel
      orderAction {
        link {
          url
          label
        }
        sponsored
      }
      company {
        companyName
        products {
          id
          slug
          productName
        }
        cms {
          settings {
            leazardFormId
          }
          logo {
            ...KatalogSanityLogo
          }
        }
      }
    }
  }
`;
