import "./Details.module.css";
import React, { ReactNode } from "react";

export interface DetailsProps {
  title: string;
  details: Detail[];
}

export interface Detail {
  key: string;
  label: string;
  value: ReactNode;
}

export const Details = ({ title, details }: DetailsProps) => {
  if (details.length === 0) {
    return null;
  }
  return (
    <>
      <h3>{title}</h3>
      <dl>
        {details.map(detail => (
          <React.Fragment key={detail.key}>
            <dt>{detail.label}</dt>
            <dd>{detail.value}</dd>
          </React.Fragment>
        ))}
      </dl>
    </>
  );
};
